import React, { useRef, Fragment } from "react"
import { useFrame } from "react-three-fiber"
import { useGLTF } from "@react-three/drei"
import { DoubleSide } from "three";

export default function Model(props) {

  const ref = useRef()
  const { nodes, materials } = useGLTF( process.env.PUBLIC_URL + '/models/zingtags.glb' )

  useFrame( ( state ) => {
    if ( ref.current === null ) return
    const t = state.clock.getElapsedTime()
  })

  return (
    <Fragment>
      <group 
        ref={ ref } 
        {...props} 
        dispose={ null }
        position={ [ 0.00, -0.38, 0.00  ]  }
        rotation={ [ 0, Math.PI*1.5, 0  ]  }
        scale={ [ 0.01, 0.01, 0.01 ] }
        receiveShadow
      >

      <mesh
        geometry={nodes.object_1007.geometry}
        material={nodes.object_1007.material}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_2004.geometry}
        material={nodes.object_2004.material}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_3004.geometry}
        material={nodes.object_3004.material}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_4001.geometry}
        material={nodes.object_4001.material}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_5.geometry}
        material={nodes.object_5.material}
        position={[0, -8.38, -4.47]}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_6.geometry}
        material={nodes.object_6.material}
        position={[0, -8.38, -4.47]}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_7.geometry}
        material={nodes.object_7.material}
        position={[0, -8.38, -4.47]}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />
      <mesh
        geometry={nodes.object_8.geometry}
        material={nodes.object_8.material}
        position={[0, -8.38, -4.47]}
        rotation={[0, Math.PI / 2, 0]}
        receiveShadow
      />


      </group>

      {/*
      <mesh position={ [ 0, -0.60, 0 ] }  >
        <boxGeometry attach="geometry" args={[ 1.15, 0.265, 0.5 ]} />
        <meshPhysicalMaterial attach="material" metalness={ 0.5 } roughness={ 0.5 } color="#fff" side={ DoubleSide } />
      </mesh>
      */}

      <mesh position={ [ 0, -0.60, 0 ] }  >
        <boxGeometry attach="geometry" args={[ 1.15, 2.0, 0.05 ]} />
        <meshPhysicalMaterial attach="material" metalness={ 0.50 } roughness={ 0.99 } color="#fff" side={ DoubleSide } />
      </mesh>

      <mesh position={ [ 0, -0.83, 0 ] } >
        <boxGeometry attach="geometry" args={[ 1264, 0.265, 1264 ]} />
        <meshPhysicalMaterial attach="material" metalness={ 0.95 } roughness={ 0.3 } color="#fff" side={ DoubleSide } />
      </mesh>

    </Fragment>
  )
}

useGLTF.preload('/models/zingtags.glb')
