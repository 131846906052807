import React from "react"

import bgImage from '../img/airtouch-logo.png';
const bgUrl = `${ bgImage }`
const Logo = props => (
  <div style={{ 
    position:'fixed', bottom:0, left:'0', 
    userSelect:'none',
    padding:'20px', 
  }} >
  <img style={{ 
    display:'block', width:'120px', 
    userSelect:'none',
    transform:'translate(-5px, 8px)', 
  }} draggable="false" alt="Logo" src={ bgUrl }/>
  </div>
)

export default Logo
