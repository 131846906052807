import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'; 
import Gallery from '../Fiber/Gallery';
import '../css/index.css';

const App = ( props ) => {
  console.log( 'App render' )
  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Switch>
          <Route exact path="/" component={ Gallery }/>
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </Fragment>
  ) 
}

export default App 
