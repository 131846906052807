import React from "react"

const Prev = props => (
  <div 
	  style={{ 
	    position:'fixed', top:'0', left:'0', userSelect:'none',
	    padding:'6px', margin:'10px 10px', backgroundColor:'rgba(0,0,0,0.0)',
	    width:'31px', height:'30px', 
	  }} 
	  onClick={ props.onClick }
  	>
		<span class="material-icons" style={{ fontSize:'32px' }} >arrow_back</span>
	</div>
)

export default Prev
