import React from "react"
import bgImage from '../img/ar.svg';
const bgUrl = `${ bgImage }`

const Ar = props => (
  <div 
  	style={{ 
	    position:'fixed', bottom:0, right:'0', 
	    userSelect:'none',
	    padding:'15px 0', 
	    marginRight:'-10px',
	    cursor:'pointer',
	  }} 
	onClick={ () => { window.open( 'https://stopover.fi/kapoor/Untitled.usdz' ) } }  
  >
	  <img style={{ 
	    display:'block', height:'60px', userSelect:'none',
	  }} draggable="false" alt="Logo" src={ bgUrl }/>

  </div>
)

export default Ar

