import React, { Fragment, Suspense, useRef, useEffect, useState } from "react"
import { PointLightHelper } from "three"
import { Canvas, useThree } from "react-three-fiber"
import { ContactShadows, Environment, OrbitControls, useHelper } from "@react-three/drei"

import Zingtags from '../models/Zingtags';

import Prev from '../Fiber/Prev';
import Next from '../Fiber/Next';

import Title from '../Fiber/Title';
import Logo from '../Fiber/Logo';
import Ar from '../Fiber/Ar';

function Scene() {
  const { scene } = useThree()
  const pointLight = useRef()
  //useHelper(pointLight, PointLightHelper, 0.5, "hotpink")

  return(

        <pointLight 
          ref={ pointLight }
          intensity={ 1.2 } 
          position={ [ 0.1, 0.25, 0.0 ] } 
          castShadow={ true }
          shadowCameraVisible={ true }
        />

  )

}

export default function Gallery() {

  const [ mesh, setMesh ] = useState( 0 )


  useEffect( () => {
  }, [ mesh ] )

  const nextMesh = () => {
    console.log( 'nextMesh' )
    let m = mesh + 1
    if ( m > 7 ) m = 0 
    setMesh( m )   
  }

  const prevMesh = () => {
    let m = mesh - 1
    if ( m < 0 ) m = 9 
    setMesh( m )   
  }

  console.log( 'mesh', mesh )


  return (
    <Fragment>


      <Canvas 
        concurrent 
        style={{ width:'100%', height:'100vh', margin:'0', background: '#fff' }} 
        pixelRatio={ window.devicePixelRatio }
        camera={{ zoom:1.8, position: [0, 0, 3 ] }}
      >

        <ambientLight intensity={ 0.15 } />

        <pointLight 
          intensity={ 0.8 } 
          position={ [ 0, 5, 0 ] } 
          castShadow
          shadowCameraVisible={ true }
        />

        <pointLight 
          intensity={ 0.4 } 
          position={ [ 0, 5, 5 ] } 
          castShadow
          shadowCameraVisible={ true }
        />

        <pointLight 
          intensity={ 0.8 } 
          position={ [ 0, 3, 2 ] } 
          color={ "rgb(128,128,128)" }
          intensity={ 0.2 }
          castShadow
          shadowCameraVisible={ true }
        />

        <pointLight 
          intensity={ 0.8 } 
          position={ [ 0, 3, -2 ] } 
          color={ "rgb(128,128,128)" }
          intensity={ 0.2 }
          castShadow
          shadowCameraVisible={ true }
        />

        {/*
        <Scene/>
        */}

        <fogExp2 attach="fog" args={[ '#fff', 0.06 ]} />

        <Suspense fallback={null} >

          <Zingtags />

          {/*
          <Environment 
            background={ false } 
            path={ process.env.PUBLIC_URL } 
            files={ '/hdr/royal_esplanade_1k.hdr' } 
          />
          */}

          {/*          
          <ContactShadows 
            rotation-x={ Math.PI / 2 } 
            position={ [ 0, -0.466, 0 ]} 
            opacity={ 0.75 } 
            width={ 3 } 
            height={ 3 } 
            blur={ 3.5 } 
            far={ 6 } 
          />
          */}

        </Suspense>

        <OrbitControls 
          minPolarAngle={ -Math.PI/2 } 
          maxPolarAngle={ Math.PI/2 } 
          enableZoom={ true } 
          enableDamping={ true }
          autoRotate={ false }
          autoRotateSpeed={ 0.5 }
          dampingFactor={ 0.025 } 
          minDistance={ 1.25 }
          maxDistance={ 4 }
          enablePan={ false } 
        />

      </Canvas>

      {/*
      <Title/>
      <Ar/>
      <Prev onClick={ prevMesh } />
      <Next onClick={ nextMesh } />
      <Logo/>
      */}


    </Fragment>
  )
}
